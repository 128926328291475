import { Popup } from 'antd-mobile'
import React, { useState } from 'react'
import { TButton as Button } from '@/components/tmd'
import { popupCloseSvg, securityKeySvg, payPinSvg } from 'assets'
import 'assets/styles/pass.css'
import { btcTypeMaps, IBtcAddressType, btcAddressTypeMaps } from 'utils'
import useLoginInfo from './useLoginInfo'
import ShowAccountItem from 'pages/manages/components/ShowAccountItem'
import { IWeb3Type } from 'proviers/web3Provider/type'
import chains from 'proviers/web3Provider/chains'
import SecurityItem from 'pages/wallet/components/SecurityItem'
import { Loading } from 'components/Loading'
import { usePopupAddressQRCode } from './popup/usePopupAddressQRCode'
import { TIcon, TIconButton } from '@/components/tmd'
import classNames from 'classnames'

const usePopup = ({
  title,
  content,
  showCloseButton = true,
  onClose,
  contentClassName,
  containerClassName,
  closeButtonClassName,
  afterClose,
  showCloseRoundButton,
  initVisible = false,
  onMaskClick,
  titleClassName,
  fullscreen = false,
  customscreen = false,
  className,
  rightIcon,
  afterShow,
  disableMaskClose = false
}: {
  title?: string | React.ReactNode
  content: React.ReactNode
  showCloseButton?: boolean
  onClose?: () => void
  contentClassName?: string
  closeButtonClassName?: string
  containerClassName?: string
  afterClose?: () => void
  showCloseRoundButton?: boolean
  initVisible?: boolean
  onMaskClick?: () => void
  className?: string
  titleClassName?: string
  fullscreen?: boolean
  customscreen?: boolean
  rightIcon?: React.ReactNode
  afterShow?: () => void
  disableMaskClose?: boolean
}) => {
  const [open, setOpen] = useState(initVisible)
  const [loading, setLoading] = useState(false)

  return {
    open,
    setOpen,
    component: (
      <Popup
        closeIcon={null}
        visible={open}
        showCloseButton={showCloseButton}
        onMaskClick={() => {
          !disableMaskClose && setOpen(false)
          onMaskClick?.()
        }}
        onClose={() => {
          onClose && onClose()
          setOpen(false)
        }}
        afterClose={afterClose}
        className={`keyboard_foot ${className}`}
        bodyStyle={{
          borderTopLeftRadius: '16px',
          borderTopRightRadius: '16px'
          // minHeight: '40vh'
        }}
        bodyClassName="bg-bg1"
        afterShow={afterShow}
      >
        <div
          className={classNames(
            'flex size-full flex-col px-5 pt-0 ',
            [customscreen ? 'h-[calc(100vh-100px)]' : ''],
            [fullscreen ? 'h-[calc(100vh-var(--popup-top-space))]' : ''],
            containerClassName
          )}
        >
          {(title || showCloseButton || showCloseRoundButton) && (
            <div
              className={
                `relative flex-shrink-0 flex h-[--popup-title-height] items-center justify-center text-[16px] font-semibold leading-[18px] ` +
                closeButtonClassName
              }
            >
              {showCloseButton && (
                <img
                  src={popupCloseSvg}
                  className={`absolute left-0 w-[24px] cursor-pointer`}
                  onClick={() => {
                    setOpen(false)
                    onClose && onClose()
                  }}
                />
              )}
              {showCloseRoundButton && (
                <TIconButton
                  className="absolute right-0 size-[36px]"
                  onClick={() => {
                    setOpen(false)
                    onClose && onClose()
                  }}
                >
                  <TIcon
                    className={classNames('text-t1')}
                    name="tg_wallet_disable"
                  />
                </TIconButton>
              )}

              {rightIcon && (
                <div className={`absolute right-0 w-[24px] cursor-pointer`}>
                  {rightIcon}
                </div>
              )}

              {title && (
                <div
                  className={
                    (showCloseButton
                      ? ' '
                      : `w-full text-t1 text-xl font-semibold `) +
                    titleClassName
                  }
                >
                  {title}
                </div>
              )}
            </div>
          )}
          <div
            className={classNames(
              'max-h-[calc(100%-var(--popup-title-height))] pb-[8px] flex w-full flex-1 flex-col items-center justify-start pt-[8px] overflow-y-auto no-scrollbar',
              contentClassName
            )}
          >
            {loading ? <Loading></Loading> : open && content}
          </div>
        </div>
      </Popup>
    )
  }
}

export const usePopupConfirm = ({
  title,
  content,
  confirmText = 'Confirm',
  cancelText = 'Cancel',
  onConfirm,
  showCloseButton = true
}: {
  title?: string | React.ReactNode
  content: string | React.ReactNode
  confirmText?: string
  cancelText?: string
  onConfirm: () => void
  showCloseButton?: boolean
}) => {
  const popup = usePopup({
    title,
    showCloseButton,
    content: (
      <div className={` flex w-full flex-col gap-[20px]`}>
        <div className={`text-xl text-[#9E9E9D]`}>{content}</div>
        <div className={'flex w-full flex-col gap-[8px]'}>
          <Button
            block
            theme="white"
            onClick={() => {
              popup.setOpen(false)
            }}
          >
            {cancelText}
          </Button>
          <Button
            block
            onClick={() => {
              onConfirm()
              popup.setOpen(false)
            }}
          >
            {confirmText}
          </Button>
        </div>
      </div>
    )
  })
  return popup
}

export const useAccountAddressPopup = () => {
  const [chainQrcode, setChainQrcode] = useState<
    | {
        web3Type: IWeb3Type
        type: IBtcAddressType | undefined
      }
    | undefined
  >()
  const {
    evmAddress,
    solAddress,
    btcAddress,
    tonAddress,
    tronAddress,
    tonAddressTest
  } = useLoginInfo()

  const renderChians = [
    ...btcAddressTypeMaps.map((type) => {
      return {
        chain: chains.btc,
        address: btcAddress[`${type}Address`],
        type: type
      }
    }),
    {
      chain: chains.ethereum,
      address: evmAddress,
      type: undefined
    },
    {
      chain: chains.solana,
      address: solAddress,
      type: undefined
    },
    {
      chain: chains.ton,
      address: tonAddress,
      type: undefined
    },
    {
      chain: chains.tonTestnet,
      address: tonAddressTest,
      type: undefined
    },
    {
      chain: chains.tron,
      address: tronAddress,
      type: undefined
    }
  ]

  const showQrcodeChainInfo = renderChians.find(
    (chain) =>
      chain.chain.type === chainQrcode?.web3Type &&
      chain.type === chainQrcode.type
  )

  const showQrAddress = showQrcodeChainInfo?.address

  const getSymbol = () => {
    const chainType = showQrcodeChainInfo?.chain.type
    switch (chainType) {
      case 'EVM':
        return showQrcodeChainInfo?.chain.chain?.nativeCurrency.symbol
      default:
        return chainType
    }
  }

  const { setOpen: setReceiveOpen, component: receiveComponent } =
    usePopupAddressQRCode({
      title: 'Account Address',
      copyable: true,
      address: showQrAddress,
      qrcodeIcon: showQrcodeChainInfo?.chain.icon,
      fullscreen: true,
      symbol: getSymbol()
    })

  return {
    renderChians,
    ...usePopup({
      fullscreen: true,
      content: (
        <>
          {receiveComponent}
          <div className="flex w-full flex-col gap-[12px]">
            {renderChians.map((chain) => {
              const address = chain.address
              if (!address) return <></>
              return (
                <ShowAccountItem
                  key={address}
                  chain={chain.chain}
                  address={address}
                  type={chain.type}
                  openQrcode={() => {
                    setChainQrcode({
                      type: chain.type,
                      web3Type: chain.chain.type
                    })
                    setReceiveOpen(true)
                  }}
                />
              )
            })}
          </div>
        </>
      )
    })
  }
}

export const usePopup2FA = ({
  onItemClick,
  onClose
}: {
  onItemClick?: (item: string) => void
  onClose?: () => void
}) => {
  const ret = usePopup({
    title: 'TOMO Wallet',
    showCloseButton: true,
    fullscreen: true,
    onClose,
    className: 'email-popup',
    content: (
      <div className="leading-[1.3] text-title">
        <h3 className="text-[20px] font-bold">
          Two-Factor Authentication (2FA)
        </h3>

        <p className="mt-[10px] text-lg leading-normal">
          To enhance the security of your account, we strongly recommend
          enabling at least one 2FA.
        </p>

        <div className="mt-[28px] flex flex-col gap-[14px]">
          <SecurityItem
            containerClass="opacity-40"
            text={'Passkeys (Face ID)'}
            icon={securityKeySvg}
            checked={false}
          />

          <SecurityItem
            text={'Pay PIN'}
            icon={payPinSvg}
            checked={false}
            onClick={() => {
              onItemClick && onItemClick('pin')
            }}
          />
        </div>
      </div>
    )
  })
  return ret
}

export default usePopup
