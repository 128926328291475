import React from 'react'
import { useTranslation } from './tmd'

export const IText = ({
  value,
  params
}: {
  value: string
  params?: Record<string, string>
}) => {
  const { t } = useTranslation()
  return <>{t(value, params)}</>
}

export default IText
